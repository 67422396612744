<template>
  <div class="compare-list">
    <!-- <div><img src="static\images\icon.png"/></div> -->
    <CompareNode v-for="(item, index) in datas" :key="index" :datas="item"></CompareNode>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, PropType } from "vue";
import CompareNode from "@/pages/wechatNirvana/components/compareNode.vue"

export default defineComponent({
  props: {
    datas: Object as PropType<GetNirvanaDisparityNode2[]>,
    default: () => []
  },
  components: { CompareNode },
  setup() {
    const dataMap = reactive({});
    return {
      ...toRefs(dataMap),
    };
  },
});
</script>

<style lang="less" scoped>
.compare-list {
  margin: 20px 10px;
  background: #fff;
  border-radius: 10px;
  color:#d30d30;
}
</style>