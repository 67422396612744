
import { defineComponent, reactive, toRefs, PropType } from "vue";
import CompareNode from "@/pages/wechatNirvana/components/compareNode.vue"

export default defineComponent({
  props: {
    datas: Object as PropType<GetNirvanaDisparityNode2[]>,
    default: () => []
  },
  components: { CompareNode },
  setup() {
    const dataMap = reactive({});
    return {
      ...toRefs(dataMap),
    };
  },
});
